<template>
    <section class="content">
        <div class="container-fluid">
             <!-- Main row -->
            <div class="row">
                <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                    <div class="card">
                        <div class="card-body table-responsive table-head">
                            <div class="row bor-bot">
                                <div class="col-md-8 pl-0 mb-3">
                                    <h1 class="m-0 text-dark pt-2 pb-2">Camp/Ski School Reports</h1>
                                </div>
                                <div class="col-md-4">  
                                    <div class="text-right pb-3">
                                        <ol class="breadcrumb float-sm-right">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                    </div>   
                                </div>
                            </div>
                            <div class="table-responsive mt-3" id="member_reg_report_table">
                                <div class="data-tablebutn pb-3 ">
                                    <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-member-report>
                                        <i class="fas fa-sliders-h"></i> Filters
                                    </b-button>
                                    <b-button class="btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                                        <i class="fas fa-redo"></i> Reset
                                    </b-button>
                                    <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                                        <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                                    </b-button>
                                </div>
                                <div class="search-box">
                                    <input type="text" align="right"  v-model="searchValue" class="input-field form-control" style="width:100%; max-width:234px" name="general_search" id="general_search" @keyup="queryForKeywords">
                                    <i class="fas fa-search" style="position:absolute"></i>
                                </div>
                                <table class="table table-sm table-bordered table-hover" id="list_table">
                                    <thead class="thead-dark">
                                    <tr>
                                        <th>Member Id</th>
                                        <th>Camp/Ski School Name</th>
                                        <th>E-Mail</th>
                                        <th>State</th>
                                        <th>Membership</th>
                                        <th>Effective From</th>
                                        <th>Effective To</th>
                                        <th>Status</th>
                                        <th class="none">Transaction Id</th>
                                        <th>Date Paid</th>
                                        <th>Coupon Code</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <!-- Data will be placed here -->
                                    <tr>
                                        <td colspan="9">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colspan="9">&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                             <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="xl"  title="Advanced Search">
                                 <p style="margin-top: -20px;margin-bottom: 30px "><strong class="text-primary">Note:</strong> <span class=" text-muted">Press Ctrl+click for multiple selection filters</span></p>
                                <form ref="form" method="post" @submit.prevent="advancedSearch()">
                                    <div class="row">
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.member_id')">
                                            <div class="form-group">
                                                <label class="form-label">Member Id</label>
                                                <input type="text" id="confirmation_code" class="form-control" v-model="searchForm.confirmation_code">
                                            </div>
                                        </div>
                                        <div class="col-md-4" >
                                            <div class="form-group">
                                                <label class="form-label">Camp/Ski School Name</label>
                                                <input type="text" id="first_name" class="form-control" v-model="searchForm.first_name">
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.email')">
                                            <div class="form-group">
                                                <label class="form-label">Email</label>
                                                <input type="text" id="email" class="form-control" v-model="searchForm.email">
                                            </div>
                                        </div>

                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.registration_from_date')">
                                            <div class="form-group">
                                                <label class="form-label">Registration From Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.registration_start_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.registration_start_date }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.registration_to_date')">
                                            <div class="form-group">
                                                <label class="form-label">Registration To Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.registration_end_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.registration_end_date }}</div>
                                            </div>
                                        </div>

                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.expiration_from_date')">
                                            <div class="form-group">
                                                <label class="form-label">Expiration From Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.expiration_start_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.expiration_start_date }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-3" v-if="this.getSiteProps('membership_reports.filters.expiration_to_date')">
                                            <div class="form-group">
                                                <label class="form-label">Expiration To Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.expiration_end_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.expiration_end_date }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.registration_year')">
                                            <div class="form-group">
                                                <label class="form-label">Registration Year</label>
                                                <select v-if="subdomain != 'alliancefastpitch'" id="year1" class="form-control" v-model="searchForm.year">
                                                    <option selected="selected" value="">Select</option>
                                                    <template v-for="year in years">
                                                        <option v-if="year >= 2018" :value="year" :key="'year' + year">{{ year }}</option>
                                                    </template>
                                                </select>
                                                <select id="season" v-if="subdomain == 'alliancefastpitch'" class="form-control" v-model="searchForm.season">
                                                  <option selected="selected" value="">Select</option>
                                                  <option v-for="(season, id) in seasons" :value="season.id" :key="id">{{ season.text }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.membership_type')">
                                            <div class="form-group">
                                                <label class="form-label">Membership Type</label>
                                                <Select2 
                                                    v-model="searchForm.membership_id" 
                                                    name="membership_id" 
                                                    id="membership_id" 
                                                    :options="membershipListSelect2"
                                                    :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" 
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.membership_status')">
                                            <div class="form-group">
                                                <label class="form-label">Membership Status</label>
                                                <Select2 
                                                    v-model="searchForm.status" 
                                                    name="status" 
                                                    id="status" 
                                                    :options="membershipStatus"
                                                    :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" 
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.transaction_id')">
                                            <div class="form-group">
                                                <label class="form-label">Transaction Id</label>
                                                <input type="text" id="transaction_id" class="form-control" v-model="searchForm.transaction_id">
                                            </div>
                                        </div>

                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.transaction_from_date')">
                                            <div class="form-group">
                                                <label for="transaction_start_date" class="form-label">Transaction From Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.transaction_start_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.transaction_start_date }}</div>
                                            </div>
                                        </div>

                                        <div class="col-md-4" v-if="this.getSiteProps('membership_reports.filters.transaction_to_date')">
                                            <div class="form-group">
                                                <label for="transaction_end_date" class="form-label">Transaction To Date</label>
                                                <div class="input-group birthdate">
                                                    <div class="birthdaypicker">
                                                        <date-pick
                                                            v-model="searchForm.transaction_end_date"
                                                            :format="'MM/DD/YYYY'"
                                                            :displayFormat="'MM/DD/YYYY'"
                                                            v-mask="'##/##/####'"
                                                            :parseDate="parseDatePick"
                                                        ></date-pick>
                                                    </div>
                                                </div>
                                                <div class="text-sm text-danger">{{ errors.transaction_end_date }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain != 'shooting' && this.getSiteProps('membership_reports.filters.state')">
                                            <div class="form-group">
                                                <label class="form-label">State/Province</label>
                                                <Select2 v-model="searchForm.state_id" name="state_id" id="state_id" :options="states" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="searchForm.country_id.some(data => data != 'USA'&& data != 'Canada') && this.getSiteProps('membership_reports.filters.state')">
                                            <div class="form-group">
                                                <label class="form-label">Other State/Province</label>
                                                <input type="text" id="other_state" class="form-control" v-model="searchForm.other_state">
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="subdomain == 'waterski'">
                                            <div class="form-group">
                                                <label class="form-label">Coupon Code</label>
                                                <Select2 v-model="searchForm.coupon_code" name="coupon_code" id="coupon_code" :options="couponcode" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                            </div>
                                        </div>

                                        <div class="col-md-12 modal-searchbtn text-center">
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-primary btn-blue">Search</button>
                                            </div>
                                            <div class="text-sm text-danger">{{ errors.not_found }}</div>
                                        </div>
                                    </div>
                                </form>
                            </b-modal>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</template>

<script>
import $ from "jquery";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

import moment from 'moment';
import DatePick from "vue-date-pick";
import axios from 'axios';

const validateName = name => {
    if (name.length || name != "") {
        return { valid: true, error: null };
    }
    return { valid: false, error: null };
};

export default {
    components: { },
    el:'#memreport',

    data() {
        return {
            searchValue:'',
            filtersApplied: false,
            exportProgress: false,
            membershipStatus:[
                {id:1,text:'Current'},
                {id:2,text:'Expired'}
            ],
            states:[],
            countries:[],
            disciplines:[],
            safe_sport_statuses:[],
            couponcode:[],
            searchForm: {
                confirmation_code:'',
                first_name: '',
                email: '',
                registration_start_date: '',
                registration_end_date: '',
                expiration_start_date: '',
                expiration_end_date: '',
                transaction_start_date: '',
                transaction_end_date: '',
                year: '',
                membership_id: [],
                status: '',
                transaction_id: '',
                region_name: '',
                state_id:[],
                country_id:[],
                other_state:'',
                citizenship:'',
                coupon_code:'',
            },
            errors: {},
            groupList:[],
            federationList:[],
            GroupModel:'',
            tags:[],
            volunteering:false,
            roles:[],
            leagues:[],
            teams: [],
            team_id:'',
            seasons:[],
            isLeague:false
        };
    },
    methods: {
       queryForKeywords(event) {
            const value = event.target.value
            this.keywords = value
            if(!isNaN(value) && value != '' && value.length == 9 & this.subdomain == 'waterski'){
            this.searchForm.confirmation_code = value;
                // this.table.search(value).draw();
                console.log('if',value);
                this.advancedSearch();
            }else{
            this.searchForm.confirmation_code = '';
                console.log('else',value);
                this.table.search(value).draw();
            }
        },
        getMembership(){
          axios.get(this.basePath + "api/getMemberships/camp_ski_school?order=a2z")
              .then((response) => {
                this.membershipData = response.data.data;
              })
              .catch(error=>{
                console.log("error getting Membership info")
              })
        },
        advancedSearch() {
            if(!this.validate()) {
                return;
            }
            this.table.draw();
            this.filtersApplied = true;
            this.$refs['modal-member-report'].hide();
        },
        validate(){
            this.errors = {};
            this.valid =  true;
            const validStartDate = validateName(this.searchForm.registration_start_date);
            this.errors.registration_start_date = validStartDate.error;

            const validEndDate = validateName(this.searchForm.registration_end_date);
            this.errors.registration_end_date = validEndDate.error;

            const validexpirationStartDate = validateName(this.searchForm.expiration_start_date);
            this.errors.expiration_start_date = validexpirationStartDate.error;

            const validexpirationEndDate = validateName(this.searchForm.expiration_end_date);
            this.errors.expiration_end_date = validexpirationEndDate.error;

            
            var dateValid = false;
            if(validStartDate.valid && !this.validateDate(this.searchForm.registration_start_date)){
                dateValid = true;
                this.errors.registration_start_date = "Invalid Date";
            }
            if(validEndDate.valid && !this.validateDate(this.searchForm.registration_end_date)){
                dateValid = true;
                this.errors.registration_end_date = "Invalid Date";
            }
            if(validexpirationStartDate.valid && !this.validateDate(this.searchForm.expiration_start_date)){
                dateValid = true;
                this.errors.expiration_start_date = "Invalid Date";
            }
            if(validexpirationEndDate.valid && !this.validateDate(this.searchForm.expiration_end_date)){
                dateValid = true;
                this.errors.expiration_end_date = "Invalid Date";
            }

            if(dateValid == false){
                if((validStartDate.valid && validEndDate.valid) && (moment(this.searchForm.registration_end_date).isBefore(this.searchForm.registration_start_date))) {
                    this.errors.registration_end_date = 'End date must be greater than Start date!';
                    return this.valid =  false;
                } else {
                    this.valid = true;
                }
            } else {
                this.valid = false;
            }

           
            if(moment(this.searchForm.expiration_end_date).isBefore(this.searchForm.expiration_start_date)) {
                this.errors.expiration_end_date = 'Expiration End date must be greater than Expiration Start date!';
                return this.valid =  false;
            } else {
                this.valid = true;
            }

            const validTStartDate = validateName(this.searchForm.transaction_start_date);
            this.errors.transaction_start_date = validTStartDate.error;

            const validTEndDate = validateName(this.searchForm.transaction_end_date);
            this.errors.transaction_end_date = validTEndDate.error;
            
            dateValid = false;
            if(validTStartDate.valid && !this.validateDate(this.searchForm.transaction_start_date)){
                dateValid = true;
                this.errors.transaction_start_date = "Invalid Date";
            }
            if(validTEndDate.valid && !this.validateDate(this.searchForm.transaction_end_date)){
                dateValid = true;
                this.errors.transaction_end_date = "Invalid Date";
            }

            if(dateValid == false){
                if((validTStartDate.valid && validTEndDate.valid) && (moment(this.searchForm.transaction_end_date).isBefore(this.searchForm.transaction_start_date))) {
                    this.errors.transaction_end_date = 'End date must be greater than Start date!';
                    return this.valid =  false;
                } else {
                    this.valid = true;
                }
            } else {
                this.valid = false;
            }

            return this.valid;
        },
        refreshList(event){
            this.searchForm = {
                confirmation_code:'',
                first_name: '',
                email: '',
                registration_start_date: '',
                registration_end_date: '',
                expiration_start_date: '',
                expiration_end_date: '',
                state_id:[],
                country_id:[],
                transaction_start_date: '',
                transaction_end_date: '',
                year: '',
                membership_id: [],
                status: '',
                transaction_id: '',
                region_name:'',
                other_state:'',
                coupon_code:''
            };
            // this.table.clear().draw();
            this.table.ajax.reload();
            this.filtersApplied = false;
        },

      Search(event) {
        this.searchValue = this.$route.query.term;
        if(this.searchValue !== undefined) {
          this.searchValue = $("#globalSearch").val();
          const dataTable = $("#list_table").DataTable();
          dataTable.search(this.searchValue).draw();
        }
      },
        exportCsv(){
            var self = this;
            $.ajax({
                url: process.env.VUE_APP_URL + "api/reports/export/prep-member-registrations",
                type: 'post',
                "data": this.table.ajax.params(),
                beforeSend: function(){
                    self.exportProgress = true;
                },
                "success": function(res, status, xhr) {
                    self.exportProgress = false;
                    if(res.file_url != undefined) window.open(res.file_url, '_blank');
                },
                error: function(err){
                    console.log(err);
                    self.exportProgress = false;
                }
            });
        },
        getState(){
            this.listOfStates.forEach(code => {
                this.states.push({ id: code['state_code']+' - '+code['state'] , text: code['state'] });
            });    
        },
        getCountry(){
            this.listOfCountries.forEach(code => {
                this.countries.push({ id: code['country'] , text: code['country'] });
            });    
        },
        getCoupon() {
        axios
            .get(this.basePath + "api/coupons/index")
            .then((response) => {
            let coupon = response.data.couponList;
            let coupon_codes = Object.keys(coupon).sort();
            coupon_codes.forEach(code => {
                this.couponcode.push({ id: code, text: code });

            });
            })        
            .catch(error => {this.errors = error.response.data.couponList});
        },
        
    },
    mounted(){
        const retry = setInterval(() => {
            this.volunteering = this.getSiteProps('volunteering.visibility');
            if(this.volunteering)
            {
                this.getVolunteeringRoles();
            }
            if(this.volunteering != undefined) clearInterval(retry);
        }, 200);

        const retry2 = setInterval(() => {
            this.GroupModel = this.getSiteProps('group_model.visible');  
            if(this.GroupModel)
            {            
            this.getGroupList();
            this.getTags();
            }          
            if(this.GroupModel != undefined) clearInterval(retry2);
        }, 200);
        this.getState();
        this.getCountry();
        this.getMembership();
        if(this.subdomain == 'waterski')
        {
            this.getCoupon();
        }

        var self = this;
        this.table = $("#list_table").DataTable({
            dom: "lfrtip",
            processing: true,
            serverSide: true,
            bFilter: true,
            responsive: true,
            ajax: {
                url: process.env.VUE_APP_URL + "api/reports/member-registrations",
                type: 'post',
                dataType: 'json',
                data: function (d) {
                    d.confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
                    d.first_name = $('#first_name').val() || self.searchForm.first_name;
                    d.email = $('#email').val() || self.searchForm.email;
                    d.start_date = $('#registration_start_date').val() || self.searchForm.registration_start_date;
                    d.end_date = $('#registration_end_date').val() || self.searchForm.registration_end_date;
                    d.expiration_start_date = $('#expiration_start_date').val() || self.searchForm.expiration_start_date;
                    d.expiration_end_date = $('#expiration_end_date').val() || self.searchForm.expiration_end_date;       
                    d.state_id = $('#state_id').val() || self.searchForm.state_id;
                    d.country_id = $('#country_id').val() || self.searchForm.country_id;
                    d.year = $("#year1").val() || self.searchForm.year;
                    d.membership_id = $('#membership_id').val() || self.searchForm.membership_id;
                    d.status = $('#status').val() || self.searchForm.status;
                    d.transaction_id = $('#transaction_id').val() || self.searchForm.transaction_id;
                    d.transaction_start_date = $("#transaction_start_date").val() || self.searchForm.transaction_start_date;
                    d.transaction_end_date = $("#transaction_end_date").val() || self.searchForm.transaction_end_date;
                    d.region_name = $('#region_name').val() || self.searchForm.region_name;
                    d.other_state = $("#other_state").val() ||self.searchForm.other_state;
                    d.member_type_id  = 7;
                    d.coupon_code = $('#coupon_code').val() || self.searchForm.coupon_code;
                    d.search['value'] = d.search['value'].replace(/^0+/, '');
                }
            },
            columns: [
                { data: 'confirmation_code', name: 'confirmation_code', render: (data)=>{return data || "N/A";}},
                { data: "name", name: "name", render: (data)=>{return data || "N/A";} },
                { data: "email", name: "email", render: (data)=>{return data || "N/A";} },
                { data: "state", name: "state", orderable: true, render: (data)=>{return data || "N/A";}, visible: this.subdomain  =="waterski", searchable: this.subdomain  =="waterski" },
                { data: "membership_name", name: "membership_name", render: (data)=>{return data || "N/A";} },
                { data: "formattedStartDate", name: "start_date", render: (data)=>{return data || "N/A";} },
                { data: "formattedValidThru", name: "valid_thru", render: (data)=>{return data || "N/A";} },
                { data: "status_txt", name: "status_txt", render: (data)=>{return data || "N/A";} },
                { data: "transaction_id", name: "transaction_id", render: (data)=>{return data || "N/A";} },
                { data: "formattedTransactionDate", name: "transaction_date", render: (data)=>{return data || "N/A";} },
                { data: "coupon_code", name: "coupon_code", render: (data)=>{return data==null?"N/A":data}, visible: this.subdomain == "waterski" ? true : false, searchable : this.subdomain == "waterski" },
                { data: "actions", name: "actions", orderable: false, render: (data,type,row,meta) => {
                    let btn = '';
                    if(this.getSiteProps('general.scoring')){
                        btn += `<a class="primary-text" href="/admin/member/${row.confirmation_code}/score/${row.member_id}" title="Shooting Score"><i class="fas fa-trophy"></i></a> `
                    }
                    if(row.membership_id == 8 && this.subdomain == "shooting")        
                        btn += `<a class="view-profile-link" href="/admin/fanclub-registrations/${row.member_id}"><i class="fas fa-eye"></i></a>`
                    else if(row.member_type_id == 7)
                      btn += `<a class="view-profile-link" href="/admin/campski-registrations/${row.member_id}"><i class="fas fa-eye"></i></a>`
                    else
                        btn += `<a class="view-profile-link" href="/admin/registrations/${row.member_id}"><i class="fas fa-eye"></i></a>` 
                    return btn
                  }
                }
            ],
            "order": [[ 9, "desc" ]],
            columnDefs: [
            ],
        });

        this.Search(); 
    },
    computed: {
        rows() {
            return this.items.length
        },
      searchTerm() {
        return this.$route.query.term;
      },
        years () {
          let currentYear = new Date().getFullYear();
          let yearsArray = [];
          for (let year = currentYear + 1; year >= 2018; year--) {
            yearsArray.push(year);
          }
          return yearsArray;
        },
        regionsListSelect2(){
            var keys = Object.keys(this.regionsList);
            return keys.map(item => {
                return { id: this.regionsList[item].code, text: this.regionsList[item].name };
            })
        },
        membershipListSelect2(){
            var keys = Object.keys(this.membershipData);
            return keys.map(key => {
                return { id: this.membershipData[key], text: key }
            });
        }
    },
  watch: {
    searchTerm(newValue) {
      if (newValue !== '') {
        this.Search();
      }
    }
  }
}
</script>